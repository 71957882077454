import { createSlice } from '@reduxjs/toolkit'
import {
  orderReturn, orderReturnSingleView,
  orderToReturnList,
  orderToReturnView,
  returnOrderList
} from 'redux/service/menuService/returnOrderService';

const initialState = {
  returnOrderListRes: { data: [], isFetching: true, error: null },
  orderReturnRes: { data: [], isFetching: true, error: null },
  orderReturnSingleViewRes: { data: [], isFetching: true, error: null },
  orderToReturnListRes: { data: [], isFetching: true, error: null },
  orderToReturnViewRes: { data: [], isFetching: true, error: null },
}

export const returnOrderSlice = createSlice({
  name: 'returnOrderSlice',
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: returnOrderList, name: "returnOrderListRes" },
      { api: orderReturn, name: "orderReturnRes" },
      { api: orderReturnSingleView, name: "orderReturnSingleViewRes" },
      { api: orderToReturnList, name: "orderToReturnListRes" },
      { api: orderToReturnView, name: "orderToReturnViewRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { returnOrderDispatch } = returnOrderSlice.actions
