import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  mergeProductOrder,
  splitAdditionalInfo,
  splitProductOrder,
} from "redux/service/menuService/orderProductService/orderproductService";

const initialState = {
  mergeProductOrderRes: { data: [], isFetching: false, error: null },
  splitProductOrderRes: { data: [], isFetching: false, error: null },
  splitAdditionalInfoRes: { data: [], isFetching: false, error: null }
};

export const orderproductSlice = createSlice({
  name: "orderproductSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: mergeProductOrder, name: "mergeProductOrderRes" },
      { api: splitProductOrder, name: "splitProductOrderRes" },
       {api: splitAdditionalInfo, name: 'splitAdditionalInfoRes'}
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { orderproductDispatch } = orderproductSlice.actions;
