import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Supplier list
export const supplierList = createAsyncThunk(
  'supplier-list',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/suppliers`, { params: bodyParams });
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
// Supplier Create Id
export const supplierCreateId = createAsyncThunk(
  'supplier-id',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/createsupplierid`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Supplier single
export const supplierSingleVeiw = createAsyncThunk(
  'supplier-single-veiw',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/supplier/${bodyParams}`);
      return response.data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })


export const supplierDelete = createAsyncThunk(
  'supplier-delete',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/supplier/${bodyParams}`);
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const supplierCreate = createAsyncThunk(
  'supplier-create',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/supplier`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

export const supplierEdit = createAsyncThunk(
  'supplier-edit',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/supplier/${bodyParams.id}`, bodyParams.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

// Bulk Upload Supplier

export const supplierBulkUpload = createAsyncThunk(
  'supplier-bulk-upload',
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/upload`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })

  ///supplier Document list 

  export const supplierDocsList = createAsyncThunk(
    "supplier-Docs-List",
    async (bodyParams, thunkAPI) => {
    
      try {
        const response = await API.get(`/supplierdocuments/${bodyParams}`);
        console.log(response.data)
        return response.data
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  )


  ///supplier Document post 

  export const supplierDocsPost = createAsyncThunk(
    "supplier-Docs-Post",
    async (bodyParams, thunkAPI) => {
      try {
        const response = await API.post(`/supplierdocuments`, bodyParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response
      }
      catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  )

    ///supplier Document post 

    export const supplierDocsDelete = createAsyncThunk(
      "supplier-Docs-Delete",
      async (bodyParams, thunkAPI) => {
        console.log(bodyParams)
        try {
          const response = await API.delete(`/supplierdocument/${bodyParams.id}`);
          return response
        }
        catch (error) {
          return thunkAPI.rejectWithValue(error);
        }
      }
    )