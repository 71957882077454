import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// Order List
export const orderList = createAsyncThunk(
  "order-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/orders`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order all List
export const allOrderList = createAsyncThunk(
  "all-list-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/allorders`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp order view
export const tempOrderView = createAsyncThunk(
  "temp-order-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/temporder/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp order view
export const tempAdditionalSingleView = createAsyncThunk(
  "additional-single-view",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/temporder/${id}`, { params: params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Customer Order Credit
export const viewAllCustomerCredit = createAsyncThunk(
  "all-customer-credit",
  async (bodyParams, thunkAPI) => {
    try {
      const { id, status, ...queryParams } = bodyParams;
      const queryString = new URLSearchParams(queryParams).toString();
      const response = await API.get(`/customer/${id}/${status}${queryString && "?" + queryString}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// temp order LIst
export const tempOrderList = createAsyncThunk(
  "temp-order-list",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/temporders`, { params: params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp Order Create
export const temporderCreate = createAsyncThunk(
  "temp-order-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/temporder`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp Order Update
export const temporderUpdate = createAsyncThunk(
  "temp-order-update",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(
        `/temporder/${bodyParams.id}`,
        bodyParams.formData
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp Order Customer change
export const temporderCustomerChange = createAsyncThunk(
  "temp-order-reassign-customer",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.put(`/reassigncustomer`, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//cancel Order
export const cancelOrderApi = createAsyncThunk(
  "cancel-order",
  async ({ params, data }, thunkAPI) => {
    try {
      const response = await API.put(`/cancelorderall/${params}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp order Whole delete
export const tempOrderSingleDelete = createAsyncThunk(
  "temp-order-single-delete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.delete(`/temporder/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// temp Order product or item Delete
export const temporderDelete = createAsyncThunk(
  "temp-order-delete",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.delete(`/temporderitem/${id}`, {
        params: params,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Product Create special offer
export const orderProductSpecialOffer = createAsyncThunk(
  "product-special-offer-create",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/speacialoffer`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Create Order Additional Info
export const orderAdditionalInfo = createAsyncThunk(
  "create-order-additional",
  async ({ id, formData }, thunkAPI) => {
    try {
      const response = await API.put(`/additionalinfo/${id}`, formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Create and Edit Order in List 
export const listpageAdditinalInfo = createAsyncThunk(
  "orderlist-additionalinfo",
  async ({ id, formData }, thunkAPI) => {
    try {
      const response = await API.put(`/orderadditionalinfodataupdate/${id}`, formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// Create Order
export const createOrder = createAsyncThunk(
  "create-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/order`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// total amount Edit
export const orderFinalTotal = createAsyncThunk(
  "order-final-total",
  async ({ params, id }, thunkAPI) => {
    try {
      const response = await API.put(`/additionalinfocharges/${id}`, params);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// get Single Order
export const orderSingle = createAsyncThunk(
  "single-order",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/order/${id}`, { params: params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// get Single Order
export const orderPrepareSingleView = createAsyncThunk(
  "single-order-prepreation",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/orderview/${id}`, { params: params });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



// Order Preparation
export const createOrderPreparation = createAsyncThunk(
  "order-preparation",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/orderprepare`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order Processing
export const createOrderProcess = createAsyncThunk(
  "order-process",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/orderprocessing`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order Dispatch
export const createOrderDispatch = createAsyncThunk(
  "order-dispatch",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/orderdispatch`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order Completed
export const createOrderCompleted = createAsyncThunk(
  "order-complete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/ordercomplete`, bodyParams, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order Completed
export const orderCompleteSingleView = createAsyncThunk(
  "order-single-complete",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/ordertransportdetails/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order In Transit List
export const orderdispatchList = createAsyncThunk(
  "order-dispatch-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/orderdispatch`, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// create Order In Transit List
export const createOrderInTransit = createAsyncThunk(
  "create-order-in-transit",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/orderintransit`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back Order
export const listBackOrderApi = createAsyncThunk(
  "list-back-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/backorder`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back Order
export const singleBackOrderApi = createAsyncThunk(
  "single-back-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/backorder/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back Order
export const backOrderDispatch = createAsyncThunk(
  "back-order-dispatch",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/backorderdispatch`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back Order
export const backOrderPreparation = createAsyncThunk(
  "back-order-preparation",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/backorderprepare`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Back Order
export const backOrderProcessing = createAsyncThunk(
  "back-order-process",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/backorderprocessing`, bodyParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Order return
export const orderShipingAdressChange = createAsyncThunk(
  "order-address-change",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/orderaddress`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Customer Check
export const checkCustomerOpenOrder = createAsyncThunk(
  "check-customer-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/checkpendingorder/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Compelete order list
export const compeleteOrderList = createAsyncThunk(
  "compelete-order-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/ordercomplete`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Delivery Status  list
export const deliveryStatusList = createAsyncThunk(
  "delivery-status-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/orderintransit`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// low Stock Order  list
export const lowStockOrderList = createAsyncThunk(
  "low-stock-order-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/lowstockorder`, { params: bodyParams });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// low Stock Single View
export const lowStockOrderSingleView = createAsyncThunk(
  "low-stock-order-view",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/lowstockorder/${bodyParams}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Cancel Back Order
export const cancelBackOrder = createAsyncThunk(
  "cancel-back-order",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await API.put(`/cancelBackOrder/${id}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Daily sheet List
export const dailySheet = createAsyncThunk(
  "daily-sheet-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/salesorderdailysheet`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Supplier Daily Sheet
export const purchaseDailySheet = createAsyncThunk(
  "purchase-sheet-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/purcahseorderdailysheet`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Carry Out

export const orderCarryOut = createAsyncThunk(
  "order-carry-out",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/carryout`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//// update Daily sheet comments
export const dailySheetComments = createAsyncThunk(
  "daily-sheet-comments",
  async (data, thunkAPI) => {
    try {
      const response = await API.put(`/drivercomments/${data.id}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//// update Daily sheet supplier comments
export const dailySheetSupplier = createAsyncThunk(
  "daily-sheet-supplier-comments",
  async (data, thunkAPI) => {
    try {
      const response = await API.put(`/purchaseordercomments/${data.id}`, data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//// update Delivery Temp Order

export const updateDelTemp = createAsyncThunk(
  "update-Delivery-Temp-Order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/updateDeliveryInTempOrder`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//all transaction list
export const transactionList = createAsyncThunk(
  "transaction-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/alltranscations`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
//single temp order
export const tempOrderSingle = createAsyncThunk(
  "single-temporder",
  async (id, thunkAPI) => {
    try {
      const response = await API.get(`/temporder/${id}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//Order-view
export const viewcancelorder = createAsyncThunk(
  "view-cancel-order",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/cancelorder/${id}`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

//Cancel-Order-view
export const cancelorderslist = createAsyncThunk(
  "cancel-orders-list",
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await API.get(`/cancelorders`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)