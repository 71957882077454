import {Checkbox} from "@mantine/core";
import React from "react";
import {Controller} from "react-hook-form";

const CheckboxField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  disabled,
  display,
  type,
  maxLength,
  prefix,
  rightSection,
  size,
  readOnly,
  onChange,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({field, fieldState: {error}}) => (
          <Checkbox
            disabled={readOnly}
            size={size ? size : "xs"}
            display={display}
            defaultChecked={field.value}
            prefix={prefix}
            type={type}
            label={label}
            variant="filled"
            placeholder={placeholder}
            error={error && error.message}
            rightSection={rightSection}
            icon={icon}
            // disabled={disabled}
            required={required}
            {...field}
            onChange={(e) => {
              field?.onChange(e);
              onChange && onChange(e?.target?.checked);
            }}
            maxLength={maxLength}
          />
        )}
      />
    </>
  );
};

export default CheckboxField;
