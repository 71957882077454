import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

//tax-list
export const taxList = createAsyncThunk(
  "tax-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/taxlist`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//transactions-list
export const transactionList = createAsyncThunk(
  "transaction-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/transactionlist`, {
        params: bodyParams,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saleList = createAsyncThunk(
  "sale-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/salesreport`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const paymentList = createAsyncThunk(
  "payment-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/paymentsummaryreport`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const storecreditList = createAsyncThunk(
  "storecredit-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/creditmemosummary`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const receivableList = createAsyncThunk(
  "receivable-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/receivable`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const productSaleList = createAsyncThunk(
  "productsales-list",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/productsales`, {
        params: bodyParams,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadReport = createAsyncThunk(
  "report-download-view",
  async ({ name, params }, thunkAPI) => {
    try {
      const response = await API.get(
        `/reports_pdf_download/${name}`,
        {
          responseType: "blob",
          params: params,
        },
        { params: params }
      ).then((blob) => {
        // const imageType = blob.type.split("/");
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.pdf`;
        a.click();
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendEmailReport = createAsyncThunk(
  "send-email",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.postForm(`/sendEmailReports/${bodyParams?.name}`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadExcelReport = createAsyncThunk(
  "excel-report-download-view",
  async ({ name, params }, thunkAPI) => {
    try {
      const response = await API.get(
        `/excelreports/${name}`,
        {
          responseType: "blob",
          params: params,
        },
        { params: params }
      ).then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob], {
            // type: "application/vnd.ms-excel",
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
