import React, { useState } from "react";
import {NumberInput} from "@mantine/core";
import {Controller} from "react-hook-form";

const NumberField = ({
  label,
  placeholder,
  control,
  name,
  icon,
  required,
  defaultValue,
  disabled,
  maxLength,
  rightSection,
  hideControls = true,
  max,
  min,
  precision,
  variant,
  readOnly,
  display,
  maw,
  onChange,
  prefix,
  suffix,
  allowNegative,
  pattern,
  decimalScale,
  leftLabel,
  miw,
  value
}) => {
  // const [inputValue, setInputValue] = useState("");
  // console.log(inputValue)
  const handleInput = (e) => {
    const inputValue = e.target.value;
    // Remove non-numeric, non-dot, and non-hyphen characters
    let cleanedValue = inputValue.replace(/[^0-9.-]/g, "");
    // setInputValue(cleanedValue);
   
    // // Split the value into integer and decimal parts
    // let [integerPart, decimalPart] = cleanedValue.split(".");

    // // Limit the decimal part to at most two digits
    // if (decimalPart !== undefined) {
    //   decimalPart = decimalPart.slice(0, 2);
    // }

    // // Combine the integer and decimal parts with a dot
    // cleanedValue =
    //   decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;

    // Update the input value
     e.target.value = cleanedValue;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({field, fieldState: {error}}) => {

          const value = field?.value;
          const numericValue = value === null || isNaN(value) ? 0 : Number(value);
   
          return (
            <NumberInput
              miw={miw}
              withAsterisk={required}
              size="xs"
              label={label}
              variant={variant ? variant : "filled"}
              placeholder={placeholder}
              error={error && error.message}
              icon={icon}
              leftLabel={leftLabel}
              disabled={disabled}
              decimalScale={decimalScale}
              display={display}
              // {...field}
               value={numericValue}
              // value={field?.value === null ? null : field?.value?.toFixed(decimalScale)}
              onChange={(e) => {
                field?.onChange(e);
                onChange && onChange(e);
              }}
              pattern={pattern}
              onInput={handleInput}
              readOnly={readOnly}
              maxLength={maxLength}
              rightSection={rightSection}
              hideControls={hideControls}
              max={max}
              precision={precision}
              prefix={prefix}
              suffix={suffix}
              allowNegative={allowNegative}
              min={min}
              maw={maw}
            />
          );
        }}
      />
    </>
  );
};

export default NumberField;
