import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "../../../../helpers/asyncActionHandler";
import {
  taxList,
  transactionList,
  saleList,
  paymentList,
  storecreditList,
  receivableList,
  productSaleList,
  downloadReport,
  sendEmailReport,
  downloadExcelReport,
} from "redux/service/reportService/financialReport";
const initialState = {
  taxListRes: { data: [], isFetching: true, error: null },
  transactionListRes: { data: [], isFetching: true, error: null },
  saleListRes: { data: [], isFetching: true, error: null },
  paymentListRes: { data: [], isFetching: true, error: null },
  storecreditListRes: { data: [], isFetching: true, error: null },
  receivableListRes: { data: [], isFetching: true, error: null },
  productSaleListRes: { data: [], isFetching: true, error: null },
  downloadReportRes: { data: [], isFetching: true, error: null },
  sendEmailReportRes: { data: [], isFetching: true, error: null },
  downloadExcelReportRes: { data: [], isFetching: true, error: null },
};

export const financialSlice = createSlice({
  name: "financialSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: taxList, name: "taxListRes" },
      { api: transactionList, name: "transactionListRes" },
      { api: saleList, name: "saleListRes" },
      { api: paymentList, name: "paymentListRes" },
      { api: storecreditList, name: "storecreditListRes" },
      { api: receivableList, name: "receivableListRes" },
      { api: productSaleList, name: "productSaleListRes" },
      { api: downloadReport, name: "downloadReportRes" },
      { api: downloadExcelReport, name: "downloadExcelReportRes" },
      { api: sendEmailReport, name: "sendEmailReportRes" },
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { reportDispatch } = financialSlice.actions;
